
import { reactive, ref } from "vue"
import InputNumber from "primevue/inputnumber"
import HardwareService from "@/services/HardwareService"
import { useConfirm } from "primevue/useconfirm"

export default {
  components: { InputNumber },
  props: {
    entity: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  emits: ["close-dialog"],
  setup(props: any, { emit }: any) {
    const confirm = useConfirm()
    const service = new HardwareService()
    const purposeOptions = ["AQS", "FIND_MWL", "STORE_DCM"]
    const modalityOptions = [
      "AR",
      "AS",
      "ASMT",
      "AU",
      "BDUS",
      "BI",
      "BMD",
      "CD",
      "CF",
      "CP",
      "CR",
      "CS",
      "CT",
      "DD",
      "DF",
      "DG",
      "DM",
      "DMS",
      "DOC",
      "DS",
      "DX",
      "EC",
      "ECG",
      "EEG",
      "EMG",
      "EOG",
      "EPS",
      "ES",
      "FA",
      "FID",
      "FS",
      "GM",
      "HC",
      "HD",
      "IO",
      "IOL",
      "IVOCT",
      "IVUS",
      "KER",
      "KO",
      "LEN",
      "LP",
      "LS",
      "MA",
      "MG",
      "MR",
      "MS",
      "NM",
      "OAM",
      "OCT",
      "OP",
      "OPM",
      "OPR",
      "OPT",
      "OPTBSV",
      "OPTENF",
      "OPV",
      "OSS",
      "OT",
      "PLAN",
      "POS",
      "PR",
      "PT",
      "PX",
      "REG",
      "RESP",
      "RF",
      "RG",
      "RTDOSE",
      "RTIMAGE",
      "RTPLAN",
      "RTRECORD",
      "RTSTRUCT",
      "RWV",
      "SEG",
      "SM",
      "SMR",
      "SR",
      "SRF",
      "ST",
      "STAIN",
      "TG",
      "US",
      "VA",
      "VF",
      "XA",
      "XC",
    ]
    const form = reactive({
      alias: "",
      aet: "",
      ip: "localhost",
      port: 0,
      manufacturer: "",
      description: "",
      purpose: "AQS",
      modality: "DX",
      minimize: false,
    })

    populateForm()
    function populateForm() {
      if (props.entity) {
        form.alias = props.entity.alias
        form.aet = props.entity.aet
        form.ip = props.entity.ip
        form.port = props.entity.port
        form.manufacturer = props.entity.manufacturer
        form.description = props.entity.description
        form.purpose = props.entity.purpose
        form.modality = props.entity.modality
        form.minimize = props.entity.minimize
      }
    }

    function addNewEntity() {
      service
        .addDicomApplicationEntity(
          props.computer ? props.computer.id : null,
          props.organization ? props.organization.id : null,
          form
        )
        .then(() => {
          emit("close-dialog")
        })
    }

    function updateEntity() {
      service.updateDicomApplicationEntity(props.entity.id, form).then(() => {
        emit("close-dialog")
      })
    }

    function deleteEntity(event: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to delete this Dicom Application Entity ?",
        accept: () => {
          service.deleteDicomApplicationEntity(props.entity.id).then(() => {
            emit("close-dialog")
          })
        },
      })
    }

    let showProcedureDetails = ref(false)
    let procedures = reactive([])
    let selectedProcedure = ref()
    let procedureCode = ref("")
    let procedureAlias = ref("")

    getProcedures()
    function getProcedures() {
      if (props.entity) {
        procedures.length = 0
        service.getDicomApplicationEntityDetails(props.entity.id).then((result: any) => {
          //service.getProcedures(result.data.id).then((result) => {
          Object.assign(procedures, result.data.procedures)
          //})
        })
      }
    }

    function addProcedure() {
      if (procedureCode.value && procedureAlias.value) {
        let codeTemp = procedureCode.value
        let aliasTemp = procedureAlias.value
        let newProcedure = {
          code: codeTemp,
          alias: aliasTemp,
        }
        service.addProcedure(props.entity.id, newProcedure).then(() => {
          showProcedureDetails.value = false
          getProcedures()
        })
      }
    }

    function editProcedure(procedure: any) {
      selectedProcedure.value = procedure
      showProcedureDetails.value = true
      if (selectedProcedure.value) {
        procedureCode.value = selectedProcedure.value.code
        procedureAlias.value = selectedProcedure.value.alias
      }
    }

    function updateProcedure() {
      if (selectedProcedure.value) {
        selectedProcedure.value.code = procedureCode.value
        selectedProcedure.value.alias = procedureAlias.value
        service.editProcedure(selectedProcedure.value.id, selectedProcedure.value).then(() => {
          showProcedureDetails.value = false
          selectedProcedure.value = null
          getProcedures()
        })
      }
    }

    function deleteProcedure(event: any, procedure: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this procedure ?",
        accept: () => {
          if (procedure) {
            service.deleteProcedure(procedure.id).then(() => {
              showProcedureDetails.value = false
              getProcedures()
            })
          }
        },
      })
    }

    return {
      purposeOptions,
      addNewEntity,
      updateEntity,
      deleteEntity,
      form,
      modalityOptions,
      procedures,
      addProcedure,
      editProcedure,
      updateProcedure,
      deleteProcedure,
      showProcedureDetails,
      procedureCode,
      procedureAlias,
      selectedProcedure,
    }
  },
}
