
import HardwareService from "@/services/HardwareService"
import { computed, reactive, ref } from "vue"
import { useRoute } from "vue-router"
import AddDicomMappingConfig from "@/components/organizations/dicoms/AddDicomMappingConfig.vue"
import AddDicomTagConfig from "@/components/organizations/dicoms/AddDicomTagConfig.vue"
import DicomMappingConfigDetailsDialog from "@/components/organizations/dicoms/DicomMappingConfigDetailsDialog.vue"
import DicomTagConfigDetailsDialog from "@/components/organizations/dicoms/DicomTagConfigDetailsDialog.vue"
import { useConfirm } from "primevue/useconfirm"
import OrganizationService from "@/services/OrganizationService"

export default {
  components: {
    AddDicomMappingConfig,
    AddDicomTagConfig,
    DicomTagConfigDetailsDialog,
    DicomMappingConfigDetailsDialog,
  },
  props: {
    dae: Object,
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props: any) {
    const route = useRoute()
    const confirm = useConfirm()
    const showMappingDetails = ref(false)
    const showTagConfigDetails = ref(false)
    const hardwareService = new HardwareService()
    const organizationService = OrganizationService.getInstance()
    const daeOutgoing = reactive([])
    const daeIncoming = reactive([])
    const organizationId = computed(() => route.params.organization_id as string)
    const selectedTagConfig = ref()
    const selectedMappingConfig = ref()
    const showAddNewTagConfig = ref(false)
    const showAddNewMappingConfig = ref(false)

    getTagConfigsForDae()
    function getTagConfigsForDae() {
      daeOutgoing.length = 0
      showAddNewTagConfig.value = false
      showTagConfigDetails.value = false
      if (props.dae) {
        hardwareService.getTagConfigsForDae(props.dae.id).then((result) => {
          ;[].push.apply(daeOutgoing, result.data)
        })
      }
    }

    function showTagConfig(tagconfig: any) {
      selectedTagConfig.value = tagconfig
      showTagConfigDetails.value = true
    }

    getMappingsForDae()
    function getMappingsForDae() {
      daeIncoming.length = 0
      showAddNewMappingConfig.value = false
      showMappingDetails.value = false
      if (props.dae) {
        hardwareService.getMappingsForDae(props.dae.id).then((result) => {
          ;[].push.apply(daeIncoming, result.data)
        })
      }
    }

    function showMapping(mappingconfig: any) {
      selectedMappingConfig.value = mappingconfig
      showMappingDetails.value = true
    }

    function deleteConfig(event: any, configId: string, mapping: boolean) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you wanto delete this config?",
        accept: () => {
          if (mapping) {
            organizationService.deleteDicomMappingConfig(configId).then(() => {
              getMappingsForDae()
            })
          } else {
            organizationService.deleteDicomTagConfig(configId).then(() => {
              getTagConfigsForDae()
            })
          }
        },
      })
    }

    return {
      showMappingDetails,
      showTagConfigDetails,
      daeIncoming,
      daeOutgoing,
      showAddNewMappingConfig,
      showAddNewTagConfig,
      organizationId,
      getTagConfigsForDae,
      getMappingsForDae,
      deleteConfig,
      selectedMappingConfig,
      selectedTagConfig,
      showTagConfig,
      showMapping,
    }
  },
}
