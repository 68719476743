
import { reactive, ref } from "vue"
import DicomApplicationEntityAddWizardLastPhase from "../hardware/DicomApplicationEntityAddWizardLastPhase.vue"
import { useToast } from "primevue/usetoast"
import OrganizationService from "@/services/OrganizationService"
import HardwareService from "@/services/HardwareService"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  emits: ["close-second"],
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props: any, { emit }: any) {
    const toast = useToast()
    const purposeOptions = [
      {
        label: "Outgoing Store",
        key: "OUTGOING_STORE",
      },
      {
        label: "Incoming Store",
        key: "INCOMING_STORE",
      },
      {
        label: "Outgoing MWL",
        key: "OUTGOING_MWL",
      },
      {
        label: "Incoming MWL",
        key: "INCOMING_MWL",
      },
      {
        label: "Outgoing MWL + Incoming Store",
        key: "OUTGOING_MWL_INCOMING_STORE",
      },
      {
        label: "Incoming MWL + Outgoing Store",
        key: "INCOMING_MWL_OUTGOING_STORE",
      },
    ]
    const form = reactive({
      aet: null,
      alias: null,
      ip: null,
      autoStore: false,
      port: null,
      description: "",
      manufacturer: "",
      minimize: false,
      modality: "DX",
      model: null,
      purpose: "OUTGOING_STORE",
    })
    const originOptions = ["CLOUD", "PC"]
    const selectedOrigin = ref("PC")
    const modalityOptions = [
      "AR",
      "AS",
      "ASMT",
      "AU",
      "BDUS",
      "BI",
      "BMD",
      "CD",
      "CF",
      "CP",
      "CR",
      "CS",
      "CT",
      "DD",
      "DF",
      "DG",
      "DM",
      "DMS",
      "DOC",
      "DS",
      "DX",
      "EC",
      "ECG",
      "EEG",
      "EMG",
      "EOG",
      "EPS",
      "ES",
      "FA",
      "FID",
      "FS",
      "GM",
      "HC",
      "HD",
      "IO",
      "IOL",
      "IVOCT",
      "IVUS",
      "KER",
      "KO",
      "LEN",
      "LP",
      "LS",
      "MA",
      "MG",
      "MR",
      "MS",
      "NM",
      "OAM",
      "OCT",
      "OP",
      "OPM",
      "OPR",
      "OPT",
      "OPTBSV",
      "OPTENF",
      "OPV",
      "OSS",
      "OT",
      "PLAN",
      "POS",
      "PR",
      "PT",
      "PX",
      "REG",
      "RESP",
      "RF",
      "RG",
      "RTDOSE",
      "RTIMAGE",
      "RTPLAN",
      "RTRECORD",
      "RTSTRUCT",
      "RWV",
      "SEG",
      "SM",
      "SMR",
      "SR",
      "SRF",
      "ST",
      "STAIN",
      "TG",
      "US",
      "VA",
      "VF",
      "XA",
      "XC",
    ]
    const hardwareOptions = [
      "Exapad",
      "Sonosite Edge II",
      "Sonosite Edge",
      "Sonoscape",
      "GE",
      "Mindray",
      "Esaote Mylab X8",
      "Samsung",
    ]
    const lastPhase = ref(false)

    const orgService = OrganizationService.getInstance()
    const orgComputers = reactive([])
    const selectedComputer = ref()

    fetchComputersForOrg()
    function fetchComputersForOrg() {
      orgService.getComputersForOrganization(props.organization.id).then((result) => {
        ;[].push.apply(orgComputers, result.rows)
        selectedComputer.value = orgComputers[0]
      })
    }

    const newEntity = reactive({})
    const hardwareService = new HardwareService()
    function addNewEntity() {
      if (selectedOrigin.value && form.purpose && form.modality) {
        if (form.purpose == "INCOMING_MWL_OUTGOING_STORE") {
          featureUnderConstruction()
        } else {
          if (selectedOrigin.value === "CLOUD") {
            selectedComputer.value = null
          }
          hardwareService
            .addDicomApplicationEntity(
              selectedComputer.value ? selectedComputer.value.computer.id : null,
              props.organization.id,
              form
            )
            .then((result) => {
              hardwareService.getDicomApplicationEntityDetails(result.data).then((result) => {
                Object.assign(newEntity, result.data)
                lastPhase.value = true
              })
            })
        }
      } else {
        toast.add({
          severity: "error",
          summary: "Warning",
          detail: "Make sure you select CLOUD/PC, the purpose and the modality are filled in",
          life: 2000,
        })
      }
    }

    function featureUnderConstruction() {
      toast.add({
        severity: "info",
        summary: "Warning",
        detail: "This feature is currently under construction",
        life: 2000,
      })
    }

    function closeSecondPhase() {
      emit("close-second")
    }

    return {
      originOptions,
      purposeOptions,
      modalityOptions,
      hardwareOptions,
      lastPhase,
      closeSecondPhase,
      orgComputers,
      selectedComputer,
      form,
      selectedOrigin,
      addNewEntity,
      newEntity,
    }
  },
  components: { DicomApplicationEntityAddWizardLastPhase, PrimaryButton },
}
