
import { reactive, ref } from "vue"
import DicomPurposeArrow from "./DicomPurposeArrow.vue"
import HardwareService from "@/services/HardwareService"
import { useConfirm } from "primevue/useconfirm"
import InputNumber from "primevue/inputnumber"
import { useToast } from "primevue/usetoast"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    origin: {
      type: String,
    },
  },
  emits: ["close-dialog"],
  setup(props: any, { emit }: any) {
    const hardwareService = new HardwareService()
    const confirm = useConfirm()
    const form = ref({
      aet: null,
      alias: null,
      ip: null,
      autoStore: null,
      port: null,
      description: null,
      manufacturer: null,
      minimize: null,
      modality: null,
      model: null,
      purpose: null,
    })

    const aetSuggestion = ref("")
    const ipSuggestion = ref("")
    const portSuggestion = ref(0)

    function handlePurpose(purpose: string) {
      if (purpose === "AQS") {
        return "Outgoing MWL + Incoming Store"
      } else if (purpose === "STORE_DCM") {
        return "Outgoing Store"
      } else if (purpose === "FIND_MWL") {
        return "Incoming MWL"
      } else {
        return purpose
      }
    }

    populateForm()
    function populateForm() {
      if (props.entity) {
        form.value.aet = props.entity.aet
        form.value.alias = props.entity.alias
        form.value.ip = props.entity.ip
        form.value.autoStore = props.entity.autoStore
        form.value.port = props.entity.port
        form.value.manufacturer = props.entity.manufacturer
        form.value.minimize = props.entity.minimize
        form.value.modality = props.entity.modality
        form.value.model = props.entity.model
        form.value.purpose = props.entity.purpose
        if (props.entity.computer === null || props.origin === "CLOUD") {
          aetSuggestion.value = "VSOLCLOUDSCP"
          ipSuggestion.value = "35.210.106.93"
          portSuggestion.value = 60000
        } else {
          aetSuggestion.value = "VSOL"
          ipSuggestion.value = ""
          portSuggestion.value = 4242
        }
      }
    }

    function updateEntity() {
      hardwareService.updateDicomApplicationEntity(props.entity.id, form.value).then(() => {
        emit("close-dialog")
      })
    }

    function deleteEntity(event: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to delete this Dicom Application Entity ?",
        accept: () => {
          hardwareService.deleteDicomApplicationEntity(props.entity.id).then(() => {
            emit("close-dialog")
          })
        },
      })
    }

    let showProcedureDetails = ref(false)
    let procedures = reactive([])
    let selectedProcedure = ref()
    let procedureCode = ref("")
    let procedureAlias = ref("")

    getProcedures()
    function getProcedures() {
      if (props.entity) {
        procedures.length = 0
        hardwareService.getDicomApplicationEntityDetails(props.entity.id).then((result: any) => {
          ;[].push.apply(procedures, result.data.procedures)
        })
      }
    }

    function addProcedure() {
      if (procedureCode.value && procedureAlias.value) {
        let codeTemp = procedureCode.value
        let aliasTemp = procedureAlias.value
        let newProcedure = {
          code: codeTemp,
          alias: aliasTemp,
        }
        hardwareService.addProcedure(props.entity.id, newProcedure).then(() => {
          showProcedureDetails.value = false
          getProcedures()
          procedureAlias.value = null
          procedureCode.value = null
        })
      }
    }

    function editProcedure(procedure: any) {
      selectedProcedure.value = procedure
      showProcedureDetails.value = true
      if (selectedProcedure.value) {
        procedureCode.value = selectedProcedure.value.code
        procedureAlias.value = selectedProcedure.value.alias
      }
    }

    function updateProcedure() {
      if (selectedProcedure.value) {
        selectedProcedure.value.code = procedureCode.value
        selectedProcedure.value.alias = procedureAlias.value
        hardwareService
          .editProcedure(selectedProcedure.value.id, selectedProcedure.value)
          .then(() => {
            showProcedureDetails.value = false
            selectedProcedure.value = null
            getProcedures()
          })
      }
    }

    function deleteProcedure(event: any, procedure: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this procedure ?",
        accept: () => {
          if (procedure) {
            hardwareService.deleteProcedure(procedure.id).then(() => {
              showProcedureDetails.value = false
              getProcedures()
            })
          }
        },
      })
    }

    const toast = useToast()

    function featureUnderConstruction() {
      toast.add({
        severity: "info",
        summary: "Warning",
        detail: "This feature is currently under construction",
        life: 2000,
      })
    }

    return {
      form,
      procedures,
      addProcedure,
      editProcedure,
      updateProcedure,
      deleteProcedure,
      showProcedureDetails,
      procedureCode,
      procedureAlias,
      selectedProcedure,
      featureUnderConstruction,
      updateEntity,
      deleteEntity,
      aetSuggestion,
      ipSuggestion,
      portSuggestion,
      handlePurpose,
    }
  },
  components: { DicomPurposeArrow, InputNumber, LinkLabel, PrimaryButton },
}
