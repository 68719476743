
import { useToast } from "primevue/usetoast"
import { ref } from "vue"
import DicomApplicationEntityAddWizardSecondPhase from "./DicomApplicationEntityAddWizardSecondPhase.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"

export default {
  emits: ["close-first"],
  components: { DicomApplicationEntityAddWizardSecondPhase, PrimaryButton, SecondaryButton },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props: any, { emit }: any) {
    const toast = useToast()
    const secondPhase = ref(false)

    function progressToSecondPhase() {
      secondPhase.value = true
    }

    function closeFirstPhase() {
      emit("close-first")
    }

    function featureUnderConstruction() {
      toast.add({
        severity: "info",
        summary: "Warning",
        detail: "This feature is currently under construction",
        life: 2000,
      })
    }

    return { featureUnderConstruction, progressToSecondPhase, secondPhase, closeFirstPhase }
  },
}
