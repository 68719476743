
import HardwareService from "@/services/HardwareService"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import ResultSet from "@/models/ResultSet"
import DicomApplicationEntitiesDetails from "./DicomApplicationEntitiesDetails.vue"
import { useRoute } from "vue-router"
import { useToast } from "primevue/usetoast"
import DicomApplicationEntityAddWizard from "../hardware/DicomApplicationEntityAddWizard.vue"
import DicomApplicationEntityAddWizardLastPhase from "../hardware/DicomApplicationEntityAddWizardLastPhase.vue"
import DicomPurposeArrow from "./DicomPurposeArrow.vue"
import DicomApplicationEntityMappings from "./DicomApplicationEntityMappings.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"

export default {
  name: "DicomApplicationEntitiesCollection",
  props: {
    sortingRouteName: {
      type: String,
    },
    computer: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  components: {
    LinkLabel,
    DicomApplicationEntitiesDetails,
    DicomApplicationEntityAddWizard,
    DicomApplicationEntityAddWizardLastPhase,
    DicomPurposeArrow,
    DicomApplicationEntityMappings,
    PrimaryButton,
    SecondaryButton,
  },
  setup(props: any) {
    let part = 0
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = ref()
    const service = new HardwareService()
    const collectionComponent = ref()
    const items = ref([])
    const loading = ref(true)
    const sort = ref("")
    const filter = ref("")
    const windowWidth = ref(window.innerWidth)
    const showSortOptions = computed(() => windowWidth.value < 960)
    const scrollable = computed(() => windowWidth.value > 960)
    const sortOptions = ref([
      { label: "Computer ↓", value: "computer.alias,asc" },
      { label: "Computer ↑", value: "computer.alias,desc" },
    ])

    const showAddWizard = ref(false)
    const showDetails = ref(false)
    let dicomDetails = ref()

    function openDetails(entity: any) {
      dicomDetails.value = null
      if (entity) {
        dicomDetails.value = entity
      }
      showDetails.value = true
    }

    loadPart(0, sort.value, filter.value)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    watch(
      () => filter.value,
      () => {
        reload()
      }
    )

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    function reload() {
      showAddWizard.value = false
      showDetails.value = false
      loadingPart.value = true
      part = 0
      loadedAllData.value = false
      items.value = []
      loadPart(part, sort.value, filter.value)
    }

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    function loadPart(part: Number, sort: any, filter: string) {
      loadingPart.value = true
      service
        .getDicomApplicationEntities(
          part,
          sort,
          filter,
          props.computer ? props.computer.id : null,
          props.organization ? props.organization.id : null
        )
        .then((result: ResultSet<any>) => {
          onPartLoaded(part, sort, result)
        })
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function sortColumn(event: any) {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    const showMappingsForDae = ref(false)
    const selectedDae = reactive({})

    function showMapping(dae: any) {
      Object.assign(selectedDae, dae)
      showMappingsForDae.value = true
    }

    const toast = useToast()
    function featureUnderConstruction() {
      toast.add({
        severity: "info",
        summary: "Warning",
        detail: "This feature is currently under construction",
        life: 2000,
      })
    }

    let frameUrl = ref("https://vsol.slab.com/embed/dicom-application-entities-i2q09cyn")

    return {
      sortColumn,
      loadPart,
      onPartLoaded,
      collectionComponent,
      items,
      loading,
      sort,
      showSortOptions,
      sortOptions,
      scrollable,
      dicomDetails,
      openDetails,
      showDetails,
      reload,
      showAddWizard,
      featureUnderConstruction,
      frameUrl,
      showMapping,
      showMappingsForDae,
      selectedDae,
    }
  },
}
