
export default {
  props: {
    purpose: {
      type: String,
    },
  },
  setup(props: any) {
    function convertPurpose(purpose: any) {
      if (purpose === "AQS" || purpose === "FIND_MWL" || purpose === "STORE_DCM") {
        if (purpose === "AQS") {
          return "Outgoing MWL + Incoming Store"
        } else if (purpose === "FIND_MWL") {
          return "Incoming MWL"
        } else if (purpose === "STORE_DCM") {
          return "Outgoing Store"
        }
      } else {
        return purpose
      }
    }

    return { convertPurpose }
  },
}
